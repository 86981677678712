@tailwind base;
@tailwind components;
@tailwind utilities;

.form-root {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    width: 100%;
}

.form-container {
    display: flex;
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    min-width: 480px;
    max-width: 480px;
    min-height: 540px;
    background-color: #F2F2F2;
    padding: 10px;
    border-radius: 10px;
}

.form-header {
    padding: 10px;
    margin: 10px;
    max-height: 92px;
    color: #282C34;
}

.loading-modal {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(67, 67, 67, 0.5);
    z-index: 1000;
}

@media screen and (max-width: 768px) {
    .form-container {
        min-width: 80%;
        max-width: 80%;

        max-height: 100%;
    }
}