.root-project-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 100vh;
    overflow: hidden;
}

.project-images-container {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50%;
    height: 75%;
    padding: 5%;
}

.project-image-paper {
    object-fit: cover;
    border-radius: 14px;
    height: 100%;
    overflow: hidden;
}

.project-image {
    object-fit: cover;
    height: 100%;
}

.project-info-container {
    display: flex;
    flex: 2;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start ;
    text-align: left;
    height: 70%;
}

@media screen and (max-width: 768px) {
    .root-project-container {
        margin-top: 15%;
        flex-direction: column;
        height: 150vh;
    }

    .project-info-container {
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        text-align: center;
        flex: 1;
    }

    .project-images-container {
        height: 50%;
        padding: 5%;
        justify-content: flex-start;
        margin-bottom: 20px;
    }

    .project-image-paper {
        height: 100%;
    }
}

.project-info-content {
    justify-content: center;
    align-items: center;
    width: 80%;
}

.project-description {
    font-size: 1.0em;
    color: var(--text-color-2);
}
