.stepper-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    max-height: 82px;
    min-height: 82px;
    width: 100%;
    border-radius: 10px;
    background-color: #F8F9FA;
    margin-bottom: 20px;
    flex: 0.9;
}

.step {
    min-width: 36px;
    height: 36px;
    border-radius: 50%;
    border: 2px solid #000;
    color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px;
    font-weight: bold;
}

.step-selected {
    border-color: #37a267;
    color: #37a267;
    transform: scale(1.2);
    transition: color 0.5s, border-color 0.5s, transform 0.3s ease;
}

.divider {
    width: 100%;
    height: 2px;
    background-color: #000;
}

.divider-selected {
    background-color: #37a267;
    transition: background-color 0.5s;
}